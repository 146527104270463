

import {  } from 'supertokens-auth-react'

const VG_API_URL = `${false ? window.location.protocol : "https:"}//api.${false ? window.location.origin : 'videogum.app'}/api/v1`

export interface VGAPIResponse<T> {
    success: boolean
    message?: string
    data?: T | null
}


export const uploadFile = async (formData: FormData): Promise<VGAPIResponse<null>> => {
    console.log("VGClient uploadFile", VG_API_URL)
    console.log(formData)

    const req = new Request(`${VG_API_URL}/assets/upload`, {
        // headers: new Headers({ 'Content-Type': 'multipart/form-data' }),
        // auth uses cookies, so we don't need to send the token here
        method: 'POST',
        body: formData,
    });
    console.log("THE REQ", req)
    // Send the FormData object to the server using fetch or XMLHttpRequest
    const resp = await fetch(req);
    if(resp.ok) {
        console.log(resp)
        return await resp.json() as VGAPIResponse<null>;
    } else {
        console.log(resp)
        throw new Error('An error occurred while uploading the file.');
    }
}
