import React from 'react';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { getSuperTokensRoutesForReactRouterDom } from 'supertokens-auth-react/ui';
import { ThirdPartyEmailPasswordPreBuiltUI } from 'supertokens-auth-react/recipe/thirdpartyemailpassword/prebuiltui';

import { SessionAuth } from "supertokens-auth-react/recipe/session";
import { AccessDeniedScreen } from 'supertokens-auth-react/recipe/session/prebuiltui';
import { UserRoleClaim, /*PermissionClaim*/ } from 'supertokens-auth-react/recipe/userroles';

import * as RouterDom from 'react-router-dom';

import DashboardPage from './pages/dashboard';
import { HomePage, SettingsPage } from './pages';
import UploadPage from './pages/upload';

interface SitePath {
    path: string;
    component: React.ComponentType<any>;
    protection_level: "public" | "private" | "admin";
    exact?: boolean;
}

const sitePaths: Array<SitePath> = [
    {
        path:'/',
        component: HomePage,
        protection_level: "public",
        exact: true
    },
    {
        path: "/dashboard",
        component: DashboardPage,
        protection_level: "private",
        exact: true
    },
    {
        path: "/settings",
        component: SettingsPage,
        protection_level: "admin",
        exact: true
    },
    {
        path: "/upload",
        component: UploadPage,
        protection_level: "private",
        exact: true
    }
]

const AdminRoute = (props: React.PropsWithChildren<any>) => {
  return (
      <SessionAuth 
          accessDeniedScreen={AccessDeniedScreen}
          overrideGlobalClaimValidators={(globalValidators) => [
              ...globalValidators, UserRoleClaim.validators.includes("admin"),
          ]
      }>
          {props.children}
      </SessionAuth>
  );
}

export const AppRouter = () => (
    <BrowserRouter>
        <Routes>
            {
                sitePaths.map(sp=>{
                    let chld: React.ReactNode;
                    switch(sp.protection_level){
                        case "public":
                            chld = <sp.component/>
                        break;
                        case "private":
                            chld = <SessionAuth
                                accessDeniedScreen={AccessDeniedScreen}
                            >
                                <sp.component/>
                            </SessionAuth>
                        break;
                        case "admin":
                            chld = <AdminRoute>
                                <sp.component/>
                            </AdminRoute>
                        break;
                    }
                    return <Route path={sp.path} key={sp.path} caseSensitive={sp.exact} element={chld} />
                })
            }
            {/* super tokens route adds /auth path for login component */}
            {getSuperTokensRoutesForReactRouterDom(RouterDom, [ThirdPartyEmailPasswordPreBuiltUI])}
        </Routes>
    </BrowserRouter>
);

export default AppRouter;
