import React, { FC } from 'react'

import { MainNav } from '../components/main-nav'
import { Search } from '../components/search'
import { UserNav } from '../components/user-nav'
import { ThemeToggle } from '../components/theme-toggle'


export interface PageProps {
    children: React.ReactNode
}

export const Page: FC<PageProps> = ({ children }) => {
    return <main>
        <div className="hidden flex-col md:flex dark:bg-background">
            <div className="border-b">
                <div className="flex h-16 items-center px-4">
                    {/* <TeamSwitcher /> */}
                    <MainNav className="mx-6" />
                    <div className="ml-auto flex items-center space-x-4">
                        <Search />
                        <ThemeToggle />
                        <UserNav />
                    </div>
                </div>
            </div>
            {children}
        </div>
    </main>
}