
import React, { FC } from 'react';
import { Page } from '../page';
import { DataTable } from '../../components/data-table';
import { columns } from '../../components/columns';
import { tasks } from '../../data/seed';

import {cn} from '../../lib/utils';
import { uploadFile } from '../../lib/vg-client';

import {useDropzone} from 'react-dropzone'

const UploadDropzone = () => {

    const [isUploading, setUploading] = React.useState<boolean>(false);

    const onDrop = React.useCallback((acceptedFiles: Array<File>) => {
        // Do something with the files
        console.log("MUH FILES", acceptedFiles)
        handleFiles(acceptedFiles)
    }, [])

    async function handleFiles(files: Array<File>) {
        console.log("got it", files)
        if(files){
            setUploading(true);
            // Create a FormData object and append the selected file
            const formData = new FormData();
            formData.append('file', files[0]);
            
            try {
                const res = await uploadFile(formData)
                console.log(res)
                
                
                // Optionally, perform any additional actions after successful upload
                
                // Clear the input field for future uploads
                //fileInput.current && fileInput.current?.value = '';
            
            } catch (error) {
                console.error(error);
            
                alert('An error occurred while uploading the file.');
            } finally {
                setUploading(false);
            }
        }
    }

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

    return isUploading ? <h2>loading...</h2> : 
        <div {...getRootProps()} className={cn("w-1/2 h-1/2 mx-auto bg-background p-6 rounded-md")}>
            <input {...getInputProps()} />
            {
            isDragActive ?
                <p>Drop the files here ...</p> :
                <p>Drag 'n' drop some files here, or click to select files</p>
            }
        </div>
    
}

// action="http://localhost:9200/api/v1/upload" method="post"

export interface UploadPageProps {
    children?: React.ReactNode;
}

export const UploadPage: FC<UploadPageProps> = ({
children
})=>{

    // const dropZone = React.useRef<HTMLDivElement>(null);
    // const fileInput = React.useRef<HTMLInputElement>(null);
    // const dropZone = document.getElementById('drop-zone');
    // const fileInput = document.getElementById('file-input');

    // React.useEffect(()=>{
    //     if(dropZone.current && fileInput.current){
    //         dropZone.current.addEventListener('click', () => {
    //             fileInput.current?.click();
    //         });
        
    //         fileInput.current.addEventListener('change', () => {
    //             if(fileInput.current?.files)
    //                 handleFiles(fileInput.current?.files);
    //         });
        
    //         dropZone.current.addEventListener('dragover', (e) => {
    //             e.preventDefault();
    //             dropZone.current?.classList.add('border-blue-500');
    //         });
        
    //         dropZone.current.addEventListener('dragleave', () => {
    //             dropZone.current?.classList.remove('border-blue-500');
    //         });
        
    //         dropZone.current.addEventListener('drop', (e: DragEvent) => {
    //             e.preventDefault();
    //             dropZone.current?.classList.remove('border-blue-500');
    //             //handleFiles(e.dataTransfer.files);
    //         });
        
    //     }
    // },[dropZone])

    // const handleFormSubmit = React.useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
    //     console.log('submit')
    //     e.preventDefault()
    // },[])

    // async function handleFiles(files: FileList | null) {
    //     console.log("got it", files)
    //     if(files){
    //         // Create a FormData object and append the selected file
    //         const formData = new FormData();
    //         formData.append('file', files[0]);
            
    //         try {
    //             const res = await uploadFile(formData)
    //             console.log(res)
                
                
    //             // Optionally, perform any additional actions after successful upload
                
    //             // Clear the input field for future uploads
    //             //fileInput.current && fileInput.current?.value = '';
            
    //         } catch (error) {
    //             console.error(error);
            
    //             alert('An error occurred while uploading the file.');
    //         }
    //     }
    // }

    
    return (
        <Page>
            <section id="upload">
                <UploadDropzone />
                {/* <div className={cn("max-w-md mx-auto bg-white p-6 rounded-md shadow-md")}>
                    <h2 className={cn("text-xl font-semibold mb-4")}>Drag and Drop Image or Video</h2>
                    <div className={cn("drop-zone")} id="drop-zone" ref={dropZone}>
                        <p className={cn("text-gray-500")}>Drag &amp; Drop files here</p>
                    </div>
                   
                    <form id="uploadForm" onSubmit={handleFormSubmit} encType="multipart/form-data" className={cn("mt-4")}>
                        <input ref={fileInput} type="file" id="file-input" name="file" className={cn("hidden")} accept="image/*,video/*" />
                        <button type="submit" className={cn("bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md")}>Upload</button>
                    </form>
                </div> */}
            </section>
            <section id="data">
                <DataTable columns={columns} data={tasks} />
                {children}
            </section>
        </Page>
    )
}

export default UploadPage;