
import { Link } from 'react-router-dom'

import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../components/ui/avatar"
import { cn } from "../lib/utils"

interface NavMenuItem {
  label: string
  to: string
  icon?: React.ReactNode
}


const menuItems: Array<NavMenuItem> = [
  {
    label: "Home",
    to: "/",

  },
  {
    label: "Dashboard",
    to: "/dashboard",

  },
  {
    label: "Upload",
    to: "/upload",
  },
  {
    label: "Settings",
    to: "/settings",
  },
]

export function MainNav({
  className,
  ...props
}: React.HTMLAttributes<HTMLElement>) {
  return (
    <nav
      className={cn("flex items-center space-x-4 lg:space-x-6", className)}
      {...props}
    >
      <Avatar className="h-9 w-9">
        <AvatarImage src="/logo192.jpg" alt="@videogum" />
        <AvatarFallback>VG</AvatarFallback>
      </Avatar>
      {
        menuItems.map((item, index) => {
          return <Link
            key={`nav_${index}`}
            to={item.to}
            className="text-sm font-medium transition-colors hover:text-primary"
          >
            {item.label}
          </Link>
        })

      }
    </nav>
  )
}