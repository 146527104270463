import React, { FC } from "react"
import { Page } from "../page"

export interface SettingsPageProps {}

export const SettingsPage: FC<SettingsPageProps> = ()=>{

    return (
        <Page>
            <h1>Settings</h1>
        </Page>
    )
}

export default SettingsPage