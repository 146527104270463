import React from 'react';
// import { Link } from 'react-router-dom';
import { cn } from '../../lib/utils';
import { Page } from '../page';

export const HomePage = () => {
    return (
        <Page>
            <section id="hero">
                <div className={cn("mx-auto max-w-2xl md:text-center")}>
                    <div className={cn("flex justify-center")}>
                        <img className='rounded-full' width={512} src="/logo512.jpg" alt="logo" />    
                    </div>
                    <h1 className={cn("mt-4 text-4xl tracking-tight")}>Videogum</h1>
                </div>
                {/* <div className={cn('flex row justify-center align-middle w-full')}>
                    <div>
                        <div className={cn('flex  justify-center align-middle h-full')}>
                            
                        </div>
                    </div>
                </div> */}
            </section>
        </Page>
    )
}